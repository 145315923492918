// @ts-check
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import useAgent from 'Lib/hooks/api/useAgent';

/**
 * The key for the notifications query.
 * @type {string}
 */
const key = 'notifications';

/**
 * useQuery for requesting notifications.
 * @param {SelectFilter} [select] - Callback function that picks a piece of data from the query response usually something like (data) => return data.filter(). Could be data | undefined.
 */
export const useNotifications = select => {
    return useQuery({
        queryKey: [key],
        staleTime: 120000,
        refetchOnWindowFocus: 'always',
        select
    });
};

export const useUnreadNotifications = () => useNotifications(data => data?.filter(({ notification }) => notification.isRead === '0'));

export const useReadNotifications = () => useNotifications(data => data?.filter(({ notification }) => notification.isRead === '1'));

export const useTodaysNotifications = () => useNotifications(data => data?.filter(({ notification }) => moment.utc(notification.dateTimeCreated).local().isSame(moment(), 'd')));

export const useYesterdaysNotifications = () =>
    useNotifications(data => data?.filter(({ notification }) => moment.utc(notification.dateTimeCreated).local().isSame(moment().subtract(1, 'day'), 'd')));

export const useOlderNotifications = () =>
    useNotifications(data => data?.filter(({ notification }) => moment.utc(notification.dateTimeCreated).local().isSameOrBefore(moment().subtract(2, 'days').startOf('day'), 'd')));

export const useBadgeCount = () => useUnreadNotifications().data?.length;

/**
 * Invalidates the notifications query.
 */
export const invalidateNotifications = () => {
    const queryClient = useQueryClient();
    queryClient.invalidateQueries({ queryKey: [key] });
};

export const useUpdateNotifications = () => {
    const { post } = useAgent();
    const queryClient = useQueryClient();
    const { data: unreadNotifications } = useUnreadNotifications();

    // useMutation hook used in exported functions
    const updateNotification = useMutation({
        mutationFn: async (/** @type {NotificationMutation} */ payload) => await post(key, payload),
        onMutate: async (/** @type {NotificationMutation} */ payload) => {
            // This kicks off the moment a mutation function starts. We can use it to immediately update the cache with an optimistic update.
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [key] });

            // Get the notifications prior to mutation
            const previousNotifications = queryClient.getQueryData([key]);

            // When the mutation starts, manually set the cache with the new data.
            // once the mutation finishes, it'll refetch the query and replace it with the server data if it's different.
            queryClient.setQueryData([key], (/** @type {NotificationReturn[]} */ old) => {
                return old.map(({ notification }) => {
                    if (payload.userSystemNotificationIDs.includes(notification.userSystemNotificationID)) {
                        return { notification: { ...notification, isRead: payload.isRead } };
                    } else {
                        return { notification };
                    }
                });
            });

            return { previousNotifications };
        },
        onError: async () => {
            // After the mutation is finished, invalidate the notifications query. Return a promise to ensure the mutation will stay in the isPending state until the query is refetched.
            // If there's an error, it'll refetch and replace the cache anyways.
            return await queryClient.invalidateQueries({ queryKey: [key] });
        }
    });

    // Exported mutation functions that limit parameters to only the necessary ones.
    /** @param {string} id */
    const markAsRead = id => updateNotification.mutate({ isRead: '1', userSystemNotificationIDs: [id] });

    /** @param {string} id */
    const markAsUnread = id => updateNotification.mutate({ isRead: '0', userSystemNotificationIDs: [id] });

    const markAllAsRead = () =>
        updateNotification.mutate({
            isRead: '1',
            userSystemNotificationIDs: unreadNotifications?.map(({ notification }) => notification.userSystemNotificationID) || []
        });

    return { markAsRead, markAllAsRead, markAsUnread };
};

export default useNotifications;
